import request from '@/utils/request'

//查询资讯列表
export function informationList(data) {
  return request({
    url: '/information/listPage',
    method: 'post',
    data
  })
}
//详情资讯
export function detailList(data) {
  // console.log(data);
  return request({
    url: '/information/getById',
    method: 'get',
    params: data
  })
}
//新增资讯
export function getAddList(data) {
  return request({
    url: '/information/insertInformationParam',
    method: 'post',
    data
  })
}
//修改资讯信息
export function getUpdateList(data) {
  // console.log(data);

  return request({
    url: '/information/updateInformationParam',
    method: 'post',
    data
  })
}
//删除资讯
export function deleteList(data) {
  return request({
    url: '/information/deleteById',
    method: 'get',
    params: data
  })
}
export function getRelevance(data) {
  return request({
    url: 'information/listInformationAndCustomerResults',
    method: 'get',
    params: data
  })
}

// 发布
export function show(data) {
  return request({
    url: '/information/show',
    method: 'get',
    params: data
  })
}

//关键词提取 
export function selectParticulars(data) {
  return request({
    url: '/NameCardController/selectParticulars',
    method: 'post',
    data
  })
}

// 资讯的关联会员
export function listInformationAndCustomerResults(data) {
  return request({
    url: '/information/listInformationAndCustomerResults',
    method: 'GET',
    params: data
  })
}


// 名帖一览
export function selectNameCardAll(data) {
  return request({
    url: '/NameCardController/selectNameCardAll',
    method: 'POST',
    data
  })
}


// 上架
export function NameCardControllerShow(data) {
  return request({
    url: '/NameCardController/show',
    method: 'get',
    params: data
  })
}

//名贴的关联会员
export function selectCustomerList(data) {
  return request({
    url: '/NameCardController/selectCustomerList',
    method: 'get',
    params: data
  })
}

//名贴回显
export function selectNameCard(data) {
  return request({
    url: '/NameCardController/selectNameCard',
    method: 'get',
    params: data
  })
}

//修改名帖
export function updateNameCard(data) {
  return request({
    url: '/NameCardController/updateNameCard',
    method: 'post',
    data
  })
}

//删除名帖
export function deleteNameCard(data) {
  return request({
    url: '/NameCardController/deleteNameCard',
    method: 'get',
    params: data
  })
}